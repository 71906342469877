import React from 'react'
import { AnimatePresence } from 'framer-motion'

const scrollBehaviour = {
  top: 0,
  left: 0,
  behavior: 'instant',
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <AnimatePresence initial={true} exitBeforeEnter onExitComplete={() => window.scrollTo(scrollBehaviour)}>
      {element}
    </AnimatePresence>
  )
}

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  return false
}
